/** @format */

.Navbar {
  display: flex;
  justify-content: space-evenly;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.25);
  background-color: #033c59;
  padding: 20px;
  align-items: center;
}
.Navbar .main {
  width: 15%;
  text-align: center;
}
.Navbar .main .fa-bars {
  display: none;
}
.Navbar .main a {
  text-decoration: none;
  color: #fff;
  font-size: 20px;
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  margin: 0;
}

.Navbar .main img {
  display: block;
  margin: auto;
  max-width: 100%;
}
.Navbar .main div {
  border-radius: 24px;
  border: 1px solid #f3f4f6;
  background: #eee;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}
.Navbar .main div input {
  border: none;
  background-color: transparent;
  outline: none;
  color: #6b7280;
  font-size: 14px;
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 80%;
}
.Navbar .main button {
  border-radius: 8px;
  background: #f5a302;
  width: 120px;
  padding: 10px;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  outline: none;
  border: 1px solid #f5a302;
}

.Navbar .main a:focus {
  font-weight: 700;
}

/* Sidebar  */
.Sidebar-Container {
  padding: 0;
  background-color: #033c59;
}
.Sidebar-Container .Close_Button {
  display: flex;
  justify-content: flex-end;
}
.Sidebar-Container .Close_Button i {
  color: #fff;
  cursor: pointer;
  font-size: 25px;
}
.Sidebar-Container .main{
  margin-top: 20px;
}
.Sidebar-Container .main a {
  text-decoration: none;
  color: #fff;
  font-size: 20px;
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  margin: 0;
}
.Sidebar-Container .main div {
  border-radius: 24px;
  border: 1px solid #f3f4f6;
  background: #eee;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}
.Sidebar-Container .main div input {
  border: none;
  background-color: transparent;
  outline: none;
  color: #6b7280;
  font-size: 14px;
  font-family: Plus Jakarta Sans;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 80%;
}
