/** @format */

@media screen and (min-width: 1000px) and (max-width: 1450px) {
  .Index_Banner_Two_Sec {
    max-width: 1200px;
  }
  .Index_Buisness_Banner {
    justify-content: space-between;
  }
  .Banner {
    min-height: 300px;
    max-height: 400px;
  }
  .About-Us_Newsletter .right .content .NewsLetter_Button {
    width: 70%;
  }
  .About-Us_Newsletter .right .content .Whatsapp_Button {
    width: 70%;
  }
  .About-Us_Newsletter .right .content .contact_Detail {
    padding-bottom: 10px;
  }
  .Contact-Us_Banner {
    min-height: 400px;
    max-height: 500px;
  }
  .Contact-Us_Banner .content {
    top: 40%;
  }
  .contact-us-overflow-main .Content .icon-div .left .container {
    width: 80%;
  }
  .contact-query-form form .submit-btn {
    width: 50%;
  }
  .contact-query-form form .Whatsapp_Button {
    width: 50%;
  }
  .Find-Work-Banner .down .main .content {
    max-width: 1200px;
  }
  .Find_work_contact_form {
    max-width: 1200px;
  }
  .Find_work_contact_form
    .right_container
    .contact-query-form
    form
    .Whatsapp_Button {
    width: 80%;
  }
  .Find_work_contact_form
    .right_container
    .contact-query-form
    form
    .submit-btn {
    width: 80%;
  }
  .Event_Booking-Banner .upper {
    max-height: 400px;
  }

  .Staff_Desc {
    max-width: 1200px;
  }
  .courses_overflow .Contact-Us_Banner {
    min-height: 400px;
    max-height: 500px;
  }
  .contact-us-overflow-main .Content .Main .search {
    width: 50%;
  }
  .permanent-job .Register_Div {
    max-width: 1200px;
  }
  .casual-staff .Find_work_contact_form .right_container {
    width: 40%;
  }
}

@media screen and (min-width: 780px) and (max-width: 1000px) {
  .Navbar {
    justify-content: space-between;
  }
  .Hide_On_Tab {
    display: none;
  }

  .Navbar .main .fa-bars {
    display: block;
    font-size: 25px;
    color: #fff;
    cursor: pointer;
  }
  .Tab_Burger {
    width: 25% !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .Banner {
    min-height: 400px;
    max-height: 700px;
  }
  .Banner h2 {
    font-size: 30px;
    line-height: normal;
  }
  .Banner .content .two_button button {
    width: 45% !important;
    font-size: 20px;
    height: 60px;
  }
  .Banner .content .Bottom_Button {
    height: 60px;
    font-size: 20px;
  }

  .Link_Section {
    left: 95%;
  }

  .Service_Slider_Container .Service_Slider .Main {
    width: 400px !important;
    height: 420px !important;
  }
  .Service_Slider_Container .Service_Slider .Main img {
    width: 100%;
  }
  .Index_Banner_Two_Sec {
    max-width: 900px !important;
  }
  .Index_Banner_Two_Sec .left img {
    width: 100%;
  }
  .Index_Banner_Two_Sec .right .content h5 {
    font-size: 30px;
    line-height: normal;
  }

  .Index_Buisness_Banner {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
  }
  .Index_Buisness_Banner .left {
    width: 90%;
    margin: auto;
  }
  .Index_Buisness_Banner .left h5 {
    font-size: 30px;
    max-width: 100%;
  }
  .Index_Buisness_Banner .left p {
    max-width: 100%;
  }
  .Index_Buisness_Banner .right {
    width: 100%;
  }
  .Index_Buisness_Banner .right img {
    max-height: 300px;
    width: auto;
  }

  .AboutUs h2 {
    padding-top: 20px;
    font-size: 30px;
  }
  .AboutUs .content {
    top: 0;
    left: 0;
    transform: none;
  }

  .About_Us-Content h5 {
    font-size: 30px;
  }
  .About_Us-Content p {
    font-size: 14px;
    margin-top: 0px;
  }

  .About_Us-two_Sec {
    width: 100%;
    align-items: center;
    padding: 20px;
  }
  .About_Us-two_Sec .left {
    width: 50%;
  }
  .About_Us-two_Sec .left img {
    width: 100%;
  }
  .About_Us-two_Sec .right {
    width: 50%;
  }
  .About_Us-two_Sec .right p {
    font-size: 14px;
  }

  .About-Us_Newsletter {
    max-height: 100%;
  }
  .About-Us_Newsletter .right .content {
    width: 100%;
    padding: 20px;
  }
  .About-Us_Newsletter .right .content h5 {
    font-size: 25px;
    line-height: normal;
  }
  .About-Us_Newsletter .right .content .desc {
    font-size: 14px;
  }
  .About-Us_Newsletter .right .content .NewsLetter_Button {
    width: 90%;
    height: 45px;
  }
  .About-Us_Newsletter .right .content .Whatsapp_Button {
    width: 90%;
    height: 45px;
  }

  .Event_Booking-Banner .upper h5 {
    font-size: 30px;
    line-height: normal;
  }
  .Event_Booking-Banner .upper p {
    margin-top: 10px;
  }
  .Event_Booking-Banner .down .main .content {
    width: 400px;
    height: 400px;
  }
  .Event_Booking-Banner .down .main .content h5 {
    font-size: 30px;
  }
  .Event_Booking-Banner .down {
    max-height: 400px;
    min-height: 300px;
  }

  .Event_Booking-Video {
    min-height: 200px;
    max-height: 300px;
    margin-top: 0;
  }
  .Event_Booking-Video img {
    max-height: 300px;
  }

  .Contact-Us_Banner {
    min-height: 400px;
    max-height: 500px;
  }
  .Contact-Us_Banner .content {
    top: 0;
    left: 0;
    transform: none;
  }

  .contact-us-overflow-main .Content {
    width: 90%;
    margin-left: 0;
    max-width: 900px;
    margin: auto;
  }
  .contact-us-overflow-main .Content .Main {
    padding: 0;
  }
  .contact-us-overflow-main .Content .Main h5 {
    font-size: 30px;
    line-height: normal;
    padding-top: 20px;
  }
  .contact-us-overflow-main .Content .Main p {
    font-size: 14px;
    line-height: normal;
    margin-top: 0 !important;
  }
  .contact-us-overflow-main .Content .office_images {
    display: grid;
    grid-template-columns: auto auto;
  }
  .contact-us-overflow-main .Content .office_images .container p {
    font-size: 30px;
  }
  .contact-us-overflow-main .Content .office_images .container {
    width: 100%;
  }
  .contact-us-overflow-main .Content .icon-div .left {
    width: 50%;
  }
  .contact-us-overflow-main .Content .icon-div .left .container {
    width: 80%;
    gap: 5px;
  }
  .contact-us-overflow-main .Content .icon-div .left .container i {
    font-size: 14px;
  }
  .contact-us-overflow-main .Content .icon-div .left .container p {
    font-size: 14px;
  }
  .paddingLeft_second {
    padding-left: 0 !important;
  }

  .contact-query-form .desc {
    font-size: 14px;
  }
  .contact-query-form form {
    max-width: 100%;
  }
  .contact-query-form form .Whatsapp_Button {
    width: 80%;
  }
  .contact-query-form form .submit-btn {
    width: 80%;
  }

  .Center_Heading h5 {
    font-size: 30px;
  }
  .sign-in-account-selector .container {
    width: 40%;
  }
  .sign-in-account-selector .container img {
    width: 100px;
    max-width: 100px;
  }

  .Find-Work-Banner .upper {
    height: 300px;
  }
  .Find-Work-Banner .down .main .content {
    width: 90%;
    background-position: center;
  }

  .Find_work_contact_form {
    display: block;
    width: 100%;
    max-width: 95%;
  }

  .Find_work_contact_form .left_container {
    width: 100%;
    background-position: center;
    text-align: center;
    padding: 20px;
  }
  .Find_work_contact_form .left_container .content {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    width: 100%;
  }
  .Find_work_contact_form .left_container .content h5 {
    text-align: center;
    font-size: 30px;
    line-height: normal;
    margin: auto;
    width: 100%;
  }
  .Find_work_contact_form .right_container {
    width: 100%;
  }

  .Center_Heading h5 {
    font-size: 30px;
  }
  .Center_Heading .desc {
    padding: 10px;
    max-width: 100%;
    width: 100%;
  }
  .Event_Booking-Banner .upper {
    height: 280px;
  }
  .Event_Booking-Banner .upper h5 {
    font-size: 30px;
  }

  .Staff_Desc {
    max-width: 750px;
  }
  .Staff_Desc h5 {
    font-size: 30px;
    line-height: normal;
  }
  .Staff_Desc p {
    font-size: 14px;
  }

  .courses_overflow .Contact-Us_Banner {
    min-height: 300px;
    max-height: 500px;
  }

  .courses_overflow .contact-us-overflow-main .office_images {
    display: flex;
    justify-content: space-between;
  }

  .courses_overflow
    .contact-us-overflow-main
    .Content
    .office_images
    .container {
    width: 50%;
  }

  .courses_overflow
    .contact-us-overflow-main
    .Content
    .office_images
    .container
    p {
    font-size: 25px;
    line-height: normal;
  }

  .courses_overflow .contact-us-overflow-main .Content .icon-div {
    margin-top: 0;
  }
  .courses_overflow .contact-us-overflow-main .Content .icon-div .left {
    width: 100%;
  }
  .courses_overflow
    .contact-us-overflow-main
    .Content
    .icon-div
    .left
    .container {
    width: 100%;
  }
  .courses_overflow
    .contact-us-overflow-main
    .Content
    .icon-div
    .left
    .container
    .desc {
    text-align: left;
  }

  .courses_overflow
    .contact-us-overflow-main
    .Content
    .icon-div
    .left
    .container
    p {
    font-size: 14px;
  }

  .Community_Page .Find_work_contact_form .left_container {
    width: 90%;
    margin: auto;
  }
  .Community_Page .Find_work_contact_form .right_container {
    width: 90%;
    margin: auto;
  }
  .Community_Page .Find_work_contact_form .left_container .content h5 {
    text-align: center;
  }
  .Community_Page .Find_work_contact_form .left_container .content {
    width: 100%;
  }
  .Community_Page .Find_work_contact_form .left_container .content p {
    text-align: center;
  }

  .Book-An-Event .Banner .content {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    padding-top: 10px;
  }
  .Book-An-Event .Find_work_contact_form {
    align-items: center;
  }
  .Book-An-Event .Find_work_contact_form .left_container .Three_Div .Item {
    width: 48%;
  }
  .Book-An-Event
    .Find_work_contact_form
    .left_container
    .Three_Div
    .Item:first-child {
    margin-top: 20px !important;
  }

  .Book-An-Event .About_Us .Item {
    margin-top: 20px;
  }

  .Book-Full-Service
    .Find_work_contact_form
    .left_container
    .About_Us
    .Three_Div {
    margin-top: 0;
  }
  .Book-Full-Service
    .Find_work_contact_form
    .left_container
    .About_Us
    .Three_Div
    .Item {
    height: 100%;
  }
  .Book-Full-Service
    .Find_work_contact_form
    .left_container
    .About_Us
    .Three_Div
    .upper {
    width: 100% !important;
  }

  .permanent-job .Banner {
    min-height: 300px;
    max-height: 400px;
  }

  .permanent-job .Register_Div {
    max-width: 760px;
  }

  .permanent-job .Register_Div .right_container img {
    width: 100% !important;
  }
  .permanent-job .contact-us-form h5 {
    font-size: 30px;
  }
  .permanent-job .contact-us-form .Form-Container {
    max-width: 760px;
  }
  .permanent-job .contact-us-form .Form-Container .left-container {
    width: 40%;
  }
  .permanent-job .contact-us-form .Form-Container .left-container img {
    width: 100%;
  }
  .permanent-job .contact-us-form .Form-Container .right-container {
    width: 60%;
  }
  .permanent-job .contact-us-form .Form-Container .right-container form {
    width: 80%;
  }
  .permanent-job
    .contact-us-form
    .Form-Container
    .right-container
    .whatsapp-button {
    width: 80%;
  }
  .permanent-job .contact-us-form .Form-Container .right-container .contact {
    width: 80%;
  }

  .permanent-job .Event_Booking-Video {
    height: 300px;
    min-height: 300px;
  }
  .casual-staff .Banner {
    min-height: 300px;
    max-height: 400px;
    padding: 20px;
  }
  .casual-staff .Banner .content {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    width: 100%;
  }

  .Find_Work_Modal .two-btn {
    display: block;
  }
  .Find_Work_Modal h6 {
    font-size: 25px;
  }
  .Find_Work_Modal .two-btn button {
    margin-top: 10px;
    font-size: 20px;
  }

  .contact-faq {
    width: 90%;
  }
  .contact-faq h5 {
    font-size: 30px;
  }
  .contact-faq .accordion .accordion-body {
    font-size: 12px;
  }

  .Chef_Modal .container {
    display: block;
  }
  .Chef_Modal .container .left {
    width: 100%;
  }
  .Chef_Modal .container .left img {
    width: 80%;
    display: block;
    margin: auto;
  }
  .Chef_Modal .container .right {
    width: 100%;
  }
  .Chef_Modal .container .right h5 {
    text-align: center;
    font-size: 30px;
  }
  .Chef_Modal .container .right .desc {
    font-size: 14px;
    text-align: center;
  }
  .Chef_Modal .container .right .small-C .two-sec p {
    font-size: 15px;
  }

  .Event-Booking-Banner .down .main {
    top: -80px;
  }

  .Footer .Upper_Div {
    width: 100%;
    padding: 20px;
  }
  .Footer .Upper_Div div {
    width: 20%;
  }
  .Footer .Upper_Div div p {
    font-size: 17px;
  }
  .Footer .Upper_Div div button {
    width: 80%;
    font-size: 14px;
  }
  .Footer .Down_Div {
    width: 100%;
    padding: 20px;
    padding-top: 0;
  }
  .Footer .Down_Div .left h5 {
    font-size: 20px;
    line-height: normal;
  }
  .Footer .Down_Div .left .two_sec {
    margin-top: 8px;
  }
  .Footer .Down_Div .left .two_sec p {
    font-size: 14px;
  }
  .Footer .Down_Div .left .copyRight {
    margin-top: 10px;
    font-size: 20px;
  }
}


@media (max-width : 768px) {
  .home_page_SingIn {
    font-size: 14px;
    width: 100px;
    height: 40px;
    top: 10px;
    right: 10px;
  }

  .imageInfo > h1 {
    font-size: 18px;
    line-height: normal;
  }

  .bold-heading {
    font-size: 24px;
  }
  
  .bartending-collapse-div .Item {
    width: 100%;
  }
  .contact-us-overflow-main .Content .Main{
    width: 100%;
    display: block;
    padding: 20px !important;
  }

  .contact-us-overflow-main .Content .Main .search {
    width: 100%;
  }

  .contact-us-overflow-main .Content .Main .search input{
    font-size: 12px;
  }

  .contact-us-overflow-main .Content .Main .search .search-icon i{
    font-size: 18px;
 
  }

  .About-Us_Newsletter {
    width: 100%;
  }

  .contact-faq h5 {
    font-size: 20px !important;
  }
  .bartending-options{
    width: 100%;
  }

  .Banner {
    max-height: 450px;
  }

  .AboutUs .content {
    top: 50px;
  }

  .AboutUs h2 {
    padding-top: 70px !important;
  }

  .Community_Page .Find_work_contact_form .right_container {
    width: 100% !important;
  }
}
